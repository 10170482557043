.awards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
}

.heading-awards {
  padding: 140px 0 30px 0;
  font-weight: 700;
  color: #ec4f43;
}

.success-des {
  width: 50vw;
  font-size: 1.3rem;
  font-weight: 500;
}

.awards-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 50px;
  width: 50vw;
}

.awards-image {
  width: 300px;
  height: 300px;
  margin: 15px;
  background-size: cover;
  background-position: center;
}

#awards-image1 {
  background-image: url("../images/success1.jpg");
}

#awards-image2 {
  background-image: url("../images/success2.jpeg");
}

#awards-image3 {
  background-image: url("../images/qyrandar.jpeg");
}

@media only screen and (max-width: 1200px) {
  .awards-card {
    width: 80vw;
  }
  .success-des {
    width: 90vw;
  }
  .awards-container {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 780px) {
  .awards-card {
    justify-content: center;
  }

  .awards-image {
    margin: 20px 0;
  }
}

@media only screen and (max-width: 500px) {
  .heading-awards {
    font-size: 2.2rem;
  }
}
