.courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: auto;
  align-items: center;
  padding: 10px 50px;
}

.h3-courses {
  padding: 140px 0 40px 0;
  color: #ec4f43;
  font-weight: 700;
}

.card {
  margin: 30px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 500px;
  text-align: start;
}

.card img {
  width: 100%;
}

.course-info {
  display: flex;
align-items: flex-start;
justify-content: flex-start;
padding-left: 30px;
}

.course-text {
  padding-top: 10px;
  font-size: 1.1rem;
}

@media only screen and (max-width: 700px) {
  .courses {
    padding-bottom: 30px;
  }

  .card {
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
    width: 100vw;
  }

  .card h4 {
    padding: 0;
  }

  .course-info {
    width: 90vw;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 0 0 0;
  }

  h2 {
    padding-top: 100px;
  }
}
