.advantages {
    background-color: #f15b50;
    color: #fff;
    min-height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-align-items: center;
    align-items: center;
    padding: 30px 15vw;
    margin-top: 60px;
}

.pros-card {
width: 350px;
margin: 10px;
padding-top: 20px;
}

.advantages img {
    width: 70px;
}

.advantages h5 {
    font-weight: 600;
    padding-top: 10px;
}

.advantages h6 {
    font-weight: 500;
    font-size: 1.5rem;
}