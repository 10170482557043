@font-face {
  font-family: NotoSans;
  src: url(./fonts/NotoSans-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(./fonts/Montserrat-Regular.ttf);
}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:is(h1, h2, h3, h4, h5, h6, p) {
  margin: 0;
}

h1 {
  font-size: 4.5rem;
  font-weight: 500;
}

h2 {
  font-size: 4rem;
  font-weight: 400;
}

h3 {
  font-size: 3rem;
  font-weight: 300;
}

h4 {
  font-size: 2.5rem;
  font-weight: 500;
}

h5 {
  font-size: 2rem;
  font-weight: 400;
}

h6 {
  font-size: 1.3rem;
  font-weight: 300;
}

p {
  font-size: 1.1rem;
}

button {
  font-size: 1.8rem;
  font-weight: 500;
}
