.intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 140px;
}

.intro-heading {
  color: #ec4f43;
  font-weight: 700;
  padding-bottom: 40px;
}

.intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 900px;
}

.intro-text {
  width: 450px;
  font-size: 1.3rem;
  text-align: left;
}

.intro-image {
  width: 450px;
}

@media only screen and (max-width: 900px) {
  .intro {
    flex-direction: column-reverse;
    width: 90vw;
  }
  .intro-text {
    font-size: 1.1rem;
    width: auto;
    padding-top: 20px;
    text-align: center;
  }
  .intro-heading {
    font-size: 2.5rem;
  }
  .intro-image {
    width: 300px;
    padding-top: 30px;
  }
}
