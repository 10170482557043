.map-container {
  padding-bottom: 200px;
}

.map-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.map-text h5 {
  font-size: 1.5rem;
  padding: 5px;
}

.heading-map {
  padding: 30px 0 70px 0;
  font-weight: 700;
  color: #ec4f43;
}

.map iframe {
  border: inset 2px black;
}

@media only screen and (max-width: 1300px) {
  .map-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
  }
  
  .map-text {
    padding-bottom: 50px;
  }

  .map-text h5 {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 800px) {
  .map iframe {
    width: 500px;
    height: 400px;
  }
}

@media only screen and (max-width: 600px) {
  .map iframe {
    width: 350px;
    height: 400px;
  }
}

@media only screen and (max-width: 500px) {
  .heading-map {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 400px) {
  .map iframe {
    width: 250px;
    height: 400px;
  }
}
