.container {
  display: flex;
  justify-content: center;
}

.first-lesson {
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.first-lesson h3 {
  padding-bottom: 60px;
  color: #ec4f43;
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 350px;
  width: 100%;
}

.form input {
  width: 100%;
  font-size: 1.5rem;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  padding-bottom: 10px;
  border-bottom: 3px solid rgb(236, 79, 67);
  color: #ec4f43;
}

.form button {
  cursor: pointer;
  font-size: 1.5rem;
  border: none;
  outline: none;
  width: auto;
  height: 60px;
  padding: 0 20px;
  background-color: rgb(236, 79, 67);
  color: white;
  border-radius: 5px;
}

.registered {
  background-color: rgba(16, 192, 16, 0.651);
  width: 50vw;
  padding: 20px 0;
  border-radius: 10px;
}

.feedback-text {
  color: rgb(43, 161, 63);
  font-weight: 600;
  font-size: 1.3rem;
  padding-top: 50px;
}

@media only screen and (max-width: 700px) {
  .first-lesson {
    padding-top: 0;
  }

  .first-lesson h3 {
    font-size: 2.5rem;
  }

  .form {
    align-items: center;
  }

  .form input {
    width: 70%;
  }

  .form button {
    font-size: 1.3rem;
  }
}
