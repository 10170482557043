.App {
  text-align: center;
}

.mx-auto {
  margin: 0 auto;
}

.flex {
  display: flex;
}

.w-full {
  width: 100%;
}

.text-left {
  text-align: left;
}