.section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f15b50;
  color: white;
}

.info-logo {
  cursor: pointer;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  width: 60vw;
  text-align: start;
  padding-top: 10px;
}

@media only screen and (max-width: 700px) {
  .section-container h5 {
    font-size: 1.2rem;
  }

  .info-container {
    width: 90vw;
  }
}
