.landing {
  background-color: rgb(54, 57, 61);
  background-image: url(../images/ernur_bg.jpg);
  background-size: cover;
  background-position: center;
  margin: 0;
  height: 75vh;
  display: flex;
  flex-direction: column;
  padding-top: 25vh;
  align-items: center;
}

:is(.landing h1, .landing h6) {
  color: whitesmoke;
}

.landing-info {
  width: 100%;
  padding: 50px 0;
  background-color: rgba(0, 0, 0, 0.411);
}

.landing h6 {
  white-space: nowrap;
  padding-top: 5px;
}

.courses-button {
  cursor: pointer;
  background-position: center;
  color: whitesmoke;
  background: rgb(236, 79, 67);
  width: 220px;
  height: 60px;
  border: none;
  border-radius: 3px;
}

.courses-button:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.726);
}

.landing-text-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10vh;
}

@media only screen and (max-width: 700px) {
  .landing h1 {
    font-size: 2.8rem;
  }
}
